<template>
  <div>
    <b-form id="sip-setting-form" @submit.prevent="onSubmit">
      <b-form-group
        id="sip-uri-group"
        label="Utilizator:"
        label-for="sip-uri"
        description="sip:user@sip-host.com"
        label-cols="4" label-cols-lg="2"
      >
        <b-form-input
          id="sip-uri"
          v-model="sipUrl"
          type="text"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="sip-password-group"
        label="Parolă"
        label-for="sip-password"
        label-cols="4" label-cols-lg="2"
      >
        <b-form-input
          id="sip-password"
          v-model="sipPassword"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="sip-ws-group"
        label="WebSocket URL"
        label-for="sip-ws"
        description="wss://server-ip:port/ws"
        label-cols="4" label-cols-lg="2"
      >
        <b-form-input
          id="sip-wsd"
          v-model="sipWs"
          required
        ></b-form-input>
      </b-form-group>

      <b-button type="submit" class="pull-right ml-2 " size="sm" variant="success">Salvează</b-button>
    </b-form>
  </div>
</template>

<script>
import config from '@/config'

export default {
  name: 'SipSettings',
  data () {
    return {
      data: [
        {
          key: 'sipUrl',
          value: '',
          user_id: this.$store.getters['auth/loggedUser'].id
        },
        {
          key: 'sipPassword',
          value: '',
          user_id: this.$store.getters['auth/loggedUser'].id
        },
        {
          key: 'sipWs',
          value: '',
          user_id: this.$store.getters['auth/loggedUser'].id
        }
      ]
    }
  },
  mounted () {
    this.$axios.get(config.baseApiUrl + '/api/users/' + this.$store.getters['auth/loggedUser'].id + '/meta')
      .then((response) => {
        response.data.data.forEach((item) => {
          this.data.forEach((it) => {
            if (it.key === item.key) {
              it.value = item.value
            }
          })
        })
      })
  },
  computed: {
    sipUrl: {
      get () {
        for (const i in this.data) {
          if (this.data[i].key === 'sipUrl') {
            return this.data[i].value
          }
        }
        return ''
      },
      set (value) {
        for (const i in this.data) {
          if (this.data[i].key === 'sipUrl') {
            this.data[i].value = value
          }
        }
      }
    },
    sipPassword: {
      get () {
        for (const i in this.data) {
          if (this.data[i].key === 'sipPassword') {
            return this.data[i].value
          }
        }
        return ''
      },
      set (value) {
        for (const i in this.data) {
          if (this.data[i].key === 'sipPassword') {
            this.data[i].value = value
          }
        }
      }
    },
    sipWs: {
      get () {
        for (const i in this.data) {
          if (this.data[i].key === 'sipWs') {
            return this.data[i].value
          }
        }
        return ''
      },
      set (value) {
        for (const i in this.data) {
          if (this.data[i].key === 'sipWs') {
            this.data[i].value = value
          }
        }
      }
    }
  },
  methods: {
    onSubmit () {
      this.$axios.post(config.baseApiUrl + '/api/users/' + this.$store.getters['auth/loggedUser'].id + '/meta', {
        meta: this.data
      })
        .then(() => {
          this.$toasted.success('Saved', {
            duration: 3000
          })
        })
    }
  }
}
</script>

<style scoped>

</style>
